const starting_sotw =
[
    [
        {
            occupant: null,
            side: null,
            divinely_inspired: false,
            is_selected: '',
            heartland: 1
        },
        {
            occupant: 'angel',
            side: 1,
            divinely_inspired: false,
            is_selected: '',
            heartland: 1
        },
        {
            occupant: 'angel',
            side: 1,
            divinely_inspired: true,
            is_selected: '',
            heartland: 1
        },
        {
            occupant: 'angel',
            side: 1,
            divinely_inspired: false,
            is_selected: '',
            heartland: 1
        },
        {
            occupant: 'angel',
            side: 1,
            divinely_inspired: false,
            is_selected: '',
            heartland: 1
        },
        {
            occupant: null,
            side: null,
            divinely_inspired: false,
            is_selected: '',
            heartland: 1
        },
    ],
    [
        {
            occupant: null,
            side: null,
            divinely_inspired: false,
            is_selected: ''
        },
        {
            occupant: 'mortal',
            side: 1,
            divinely_inspired: false,
            is_selected: ''
        },
        {
            occupant: 'mortal',
            side: 1,
            divinely_inspired: false,
            is_selected: ''
        },
        {
            occupant: 'mortal',
            side: 1,
            divinely_inspired: false,
            is_selected: ''
        },
        {
            occupant: 'mortal',
            side: 1,
            divinely_inspired: false,
            is_selected: ''
        },
        {
            occupant: null,
            side: null,
            divinely_inspired: false,
            is_selected: ''
        },
    ],
    [
        {
            occupant: null,
            side: null,
            divinely_inspired: false,
            is_selected: ''
        },
        {
            occupant: null,
            side: null,
            divinely_inspired: false,
            is_selected: ''
        },
        {
            occupant: null,
            side: null,
            divinely_inspired: false,
            is_selected: ''
        },
        {
            occupant: null,
            side: null,
            divinely_inspired: false,
            is_selected: ''
        },
        {
            occupant: null,
            side: null,
            divinely_inspired: false,
            is_selected: ''
        },
        {
            occupant: null,
            side: null,
            divinely_inspired: false,
            is_selected: ''
        },
    ],
    [
        {
            occupant: null,
            side: null,
            divinely_inspired: false,
            is_selected: ''
        },
        {
            occupant: null,
            side: null,
            divinely_inspired: false,
            is_selected: ''
        },
        {
            occupant: null,
            side: null,
            divinely_inspired: false,
            is_selected: ''
        },
        {
            occupant: null,
            side: null,
            divinely_inspired: false,
            is_selected: ''
        },
        {
            occupant: null,
            side: null,
            divinely_inspired: false,
            is_selected: ''
        },
        {
            occupant: null,
            side: null,
            divinely_inspired: false,
            is_selected: ''
        },
    ],
    [
        {
            occupant: null,
            side: null,
            divinely_inspired: false,
            is_selected: ''
        },
        {
            occupant: null,
            side: null,
            divinely_inspired: false,
            is_selected: ''
        },
        {
            occupant: null,
            side: null,
            divinely_inspired: false,
            is_selected: ''
        },
        {
            occupant: null,
            side: null,
            divinely_inspired: false,
            is_selected: ''
        },
        {
            occupant: null,
            side: null,
            divinely_inspired: false,
            is_selected: ''
        },
        {
            occupant: null,
            side: null,
            divinely_inspired: false,
            is_selected: ''
        },
    ],
    [
        {
            occupant: null,
            side: null,
            divinely_inspired: false,
            is_selected: ''
        },
        {
            occupant: null,
            side: null,
            divinely_inspired: false,
            is_selected: ''
        },
        {
            occupant: null,
            side: null,
            divinely_inspired: false,
            is_selected: ''
        },
        {
            occupant: null,
            side: null,
            divinely_inspired: false,
            is_selected: ''
        },
        {
            occupant: null,
            side: null,
            divinely_inspired: false,
            is_selected: ''
        },
        {
            occupant: null,
            side: null,
            divinely_inspired: false,
            is_selected: ''
        },
    ],
    [
        {
            occupant: null,
            side: null,
            divinely_inspired: false,
            is_selected: ''
        },
        {
            occupant: null,
            side: null,
            divinely_inspired: false,
            is_selected: ''
        },
        {
            occupant: null,
            side: null,
            divinely_inspired: false,
            is_selected: ''
        },
        {
            occupant: null,
            side: null,
            divinely_inspired: false,
            is_selected: ''
        },
        {
            occupant: null,
            side: null,
            divinely_inspired: false,
            is_selected: ''
        },
        {
            occupant: null,
            side: null,
            divinely_inspired: false,
            is_selected: ''
        },
    ],
    [
        {
            occupant: null,
            side: null,
            divinely_inspired: false,
            is_selected: ''
        },
        {
            occupant: 'mortal',
            side: 2,
            divinely_inspired: false,
            is_selected: ''
        },
        {
            occupant: 'mortal',
            side: 2,
            divinely_inspired: false,
            is_selected: ''
        },
        {
            occupant: 'mortal',
            side: 2,
            divinely_inspired: false,
            is_selected: ''
        },
        {
            occupant: 'mortal',
            side: 2,
            divinely_inspired: false,
            is_selected: ''
        },
        {
            occupant: null,
            side: null,
            divinely_inspired: false,
            is_selected: ''
        },
    ],
    [
        {
            occupant: null,
            side: null,
            divinely_inspired: false,
            is_selected: '',
            heartland: 2
        },
        {
            occupant: 'angel',
            side: 2,
            divinely_inspired: false,
            is_selected: '',
            heartland: 2
        },
        {
            occupant: 'angel',
            side: 2,
            divinely_inspired: false,
            is_selected: '',
            heartland: 2
        },
        {
            occupant: 'angel',
            side: 2,
            divinely_inspired: true,
            is_selected: '',
            heartland: 2
        },
        {
            occupant: 'angel',
            side: 2,
            divinely_inspired: false,
            is_selected: '',
            heartland: 2
        },
        {
            occupant: null,
            side: null,
            divinely_inspired: false,
            is_selected: '',
            heartland: 2
        }
    ]
]

export default starting_sotw